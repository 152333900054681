import $ from "jquery"
import 'jquery-lazy'
import 'bootstrap'

function loadIcons() {
  $(".icon-dropdown .icon-dropdown-lazy").lazy()
}

export function lazyIconDropdown() {
  if (document.querySelector(".icon-dropdown") !== null) {
    $("#navbarDropdown").on("show.bs.dropdown", () => {
      loadIcons()
    })
    $(".menu-bar").on("show.bs.collapse", () => {
      loadIcons()
    })
  }
}
