import $ from "jquery"

/**
 * remove thousands separators from a money string
 */

function removeThousands(moneyString) {
  /* eslint-disable no-useless-escape */
  return moneyString.replace(/[\.,][0-9]{3}/g, match => {
    return match.replace(/[^0-9]+/g, "")
  })
}

/**
 * parse a string that designates a monetary amount and return a Number eg:
 * $30,989,878.78
 */

function parseMoney(price) {
  const priceWithoutSymbol = price.replace(/^[^0-9,\.-]+/, "")
  const priceWithoutThousands = removeThousands(priceWithoutSymbol).replace(",", ".")
  return parseFloat(priceWithoutThousands, 10)
}

/**
 * determine the product quantity fora product option.
 */

function quantityForSelectedInput(input) {
  const optionName = $(input).attr("name") + "_quantity"
  const quantityInput = $("input[name=" + optionName + "]")
  if (!quantityInput.length) {
    return 1
  }
  const quantity = parseInt(quantityInput.val(), 10)
  if (isNaN(quantity)) {
    return 1
  }
  return quantity
}

/**
 * summate over all the selected options that cost money.
 */

function optionValueSum(price, attributeName) {
  return $("input[value^='$product:']:checked, option[value^='$product:']:selected").map((index, productOption) => {
    return parseFloat($(productOption).data(attributeName)) * quantityForSelectedInput(productOption)
  })
    .toArray()
    .reduce((sum, value) => {
      return sum + value
    }, parseFloat(price))
}

/**
 * compute the total price by adding the price of the selected options to
 * the passed amount. The returned price is formatted according to the active
 * locale.
 */

function computeTotalPrice(amount, currency, documentLanguage, attributeName) {
  // compute total price by finding all active sections and summing over them.
  const total = optionValueSum(amount, attributeName)
  const formattedTotalPrice = total.toLocaleString(
    documentLanguage,
    {
      currency,
      style: "currency",
    }
  )
  return formattedTotalPrice
}

/**
 * this function sets the amount and the price as a data attribute if it is missing.
 * It is important because that designates the base price of the product.
 */

function setInitialAmount(node) {
  if (!node.length) {
    return
  }

  if (node.data("amount") === undefined) {
    const initialPrice  = node.text()
    const initialAmount = parseMoney(initialPrice)
    // create data attribute with the initial price, parsed as a number.
    // we only do this, because while the form is rendered by a tempatetag,
    // which is not easily changed or overridden, the amount payable is rendered
    // plainly in the template. That means depending on the availability of the
    // data-amount or data-price attribute could be super fragile.
    node.attr('data-amount', initialAmount)
    node.attr('data-price', initialPrice)
  } else {
    // initialize price based on data attribute
    const initialAmount = parseMoney(node.attr("data-amount"))
    node.data('amount', initialAmount)
  }
}

/**
 * write the amount and totalprice into the data attributes and html node
 */

function setAmountAndTotalPrice($valueCarryingHtmlNode, attributeName, target) {
  if (!target.length) {
    return
  }
  const currency = $valueCarryingHtmlNode.data("currency")
  const amount = $valueCarryingHtmlNode.data(attributeName)
  target.data("amount", amount)
  const documentLanguage = document.documentElement.lang
  const formattedPrice = computeTotalPrice(amount, currency, documentLanguage, attributeName)
  target.text(formattedPrice)
}

function setOptionAmountAndTotalPrice($valueCarryingHtmlNode, attributeName, target) {
  if (!target.length) {
    return
  }
  // get all the needed values to computed the total price
  const amount = target.data("amount")
  let currency = $valueCarryingHtmlNode.data("currency")
  if (!currency) {
    currency = $("[value^='$product:']").first()
      .data("currency")
  }

  const documentLanguage = document.documentElement.lang
  // compute total price by finding all active sections and summing over them.
  const formattedTotalPrice = computeTotalPrice(amount, currency, documentLanguage, attributeName)
  target.text(formattedTotalPrice)
}

/**
 * add event listeners to all form controls on the add to basket form
 * that change the payable amount.
 *
 * When such an option is selected, update the payable amount.
 */

export function initializePriceUpdate() {
  const $productPriceInclTax = $("#content_inner .price_incl_tax").first()
  const $productPriceExclTax = $("#content_inner .price_excl_tax").first()
  if ($productPriceInclTax.length + $productPriceExclTax.length) {
    setInitialAmount($productPriceInclTax)
    setInitialAmount($productPriceExclTax)

    // handle selection of variants, the base price of the item will change,
    // so update the data attributes on the initial price.
    $("[name=child_id]").change(evt => {
      let $valueCarryingHtmlNode = $(evt.target).find("option:selected")
      if (!$valueCarryingHtmlNode.length) {
        $valueCarryingHtmlNode = $(evt.target)
      }

      setAmountAndTotalPrice($valueCarryingHtmlNode, "amount-incl-tax", $productPriceInclTax)
      setAmountAndTotalPrice($valueCarryingHtmlNode, "amount-excl-tax", $productPriceExclTax)
    })

    // find all the controls that are product options with a price
    const $checkboxesAndRadioSelectors = $("input[value^='$product:'],input.reset,input.quantity")
    const $selectBoxes = $("option[value^='$product:']").parent()
    const $productOptions = $checkboxesAndRadioSelectors.add($selectBoxes)

    // handle changes to product option controls
    $productOptions.change(evt => {
      // based on the events target, find the node that contains the data
      // attributes.
      let $valueCarryingHtmlNode = $(evt.target).find("option:selected")
      if (!$valueCarryingHtmlNode.length) {
        $valueCarryingHtmlNode = $(evt.target)
      }

      setOptionAmountAndTotalPrice($valueCarryingHtmlNode, "amount-incl-tax", $productPriceInclTax)
      setOptionAmountAndTotalPrice($valueCarryingHtmlNode, "amount-excl-tax", $productPriceExclTax)
    })
  }
}
