import $ from "jquery"
import { Modal } from 'bootstrap'


export function initModalPopup() {
  $("[data-popup]").each(function initializePopup() {
    const key = $(this).data("key")
    if (key){
      const accept = localStorage.getItem(key)
      if (!accept){
        const modal = new Modal($(this), {})
        modal.show()
        localStorage.setItem(key, 'True')
      }
    } else {
      const modal = new Modal($(this), {})
      modal.show()
    }
  })
}
