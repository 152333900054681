export function initParallax() {
  const parallaxNodes = document.querySelectorAll('.parallax')
  if (parallaxNodes.length) {
    import(

      /* webpackChunkName: "parallax" */
      'rellax'
    ).then(({default:Rellax}) => {
      return new Rellax(".parallax-image", {
        center:true,
        speed: -5,
      })
    })
  }
}
