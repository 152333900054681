import $ from "jquery"

export function initImageZoom() {
  $("[data-image-zoom]").each(function initializeImageZoom() {
    let target = $(this).find("img")
    $(this).on("mousemove", function moveOnImage(evt){
      let imgWidth = $(target).innerWidth()
      let imgHeight = $(target).innerHeight()
      let offset = $(this).offset()

      // calculate mouse position relative to the image
      let relativeX = evt.pageX - offset.left
      let relativeY = evt.pageY - offset.top

      // calculate the position to translate to, keeping the cursor position fixed
      let translateX = ((relativeX - imgWidth) / 2) * -1
      let translateY = ((relativeY - imgHeight) / 2) * -1

      // apply the transformation
      $(target).css("transform", "translate(" + translateX + "px, " + translateY + "px) scale(2)")
    })

    // remove the css so it reverts to original
    $(this).on("mouseleave", () => {
      $(target).css("transform", "")
    })
  })
}
