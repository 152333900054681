import $ from "jquery"

export function IconHoverFix(selector = '.icon-dropdown > li.nav-item') {
  if (document.querySelector(selector) !== null) {
    if ($(window).innerWidth() > 991){
      let currentActiveMenu = null
      const menuItems = $(selector)

      menuItems.mousemove(function handleMouseMove(e){
        const mouseMoveEvent = e.originalEvent
        // if we are moving mostly vertically, activate hover,
        // but if we are moving a lot in the horizontal direction,
        // it means we want to click on a submenu, so do not change hover
        if (Math.abs(mouseMoveEvent.movementX) < 1 && currentActiveMenu != this) {
          /* eslint-disable consistent-this */
          currentActiveMenu = this
          menuItems.removeClass("hover")
          $(this).addClass("hover")
        }
      })
    }
  }
}
