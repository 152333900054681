import $ from 'jquery'
import 'magnific-popup/dist/magnific-popup.css'


export function initPopup(selector, config={type: 'image', gallery: { enabled:true }}) {
  if (document.querySelector(selector) !== null) {
    import(

      /* webpackChunkName: "magnificpopup" */
      'magnific-popup').then(() => {
      return $(selector).magnificPopup(config)
    })
  }
}
