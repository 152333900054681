import $ from "jquery"

export function initHighlightedSlider() {
  $(".plus").on("click", function handleClick () {
    $(".product-information").removeClass("active")
    const target = $(this).data("target")
    const dots = $(this).closest(".highlight_slider")
      .find(target)
    $(dots).toggleClass("active")
    $(".highlight_slider-overlay").addClass("active")
  })
  $("html").on("click", ".highlight_slider-overlay, .close", () => {
    $(".highlight_slider-overlay").removeClass("active")
    $(".product-information").removeClass("active")
  })
}
